<!-- Humi logo -->
<app-humi-logo size="tiny" labelOrientation="horizontal" />

<!-- Open hello sign container if the offer letter is not signed -->
<div *ngIf="!isSigned && signingUrl" class="ui basic segment" [class.loading]="isLoading">
    <div #helloSignContainer></div>
</div>

<!-- If offer letter is signed -->
<div *ngIf="isSigned" class="ui narrow basic segment" [class.loading]="isLoading">
    <div class="text">
        <h2 class="header">{{ 'offerLetterSign.accepted' | translate }}</h2>
        <p>{{ 'offerLetterSign.copySentToEmail' | translate }}</p>
    </div>
</div>

<!-- If offer letter does not exist -->
<div *ngIf="!isLoading && !signingUrl" class="ui narrow basic segment" [class.loading]="isLoading">
    <div class="text">
        <h2 class="header">{{ 'offerLetterSign.notFound' | translate }}</h2>
        <p>{{ 'offerLetterSign.linkExpired' | translate }}</p>
    </div>
</div>
