import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AppResources } from '@app/app.resources';
import { ErrorParser } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { JsonApiError } from '@app/errors/json-api.error';
import { AuthService, NotifyService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';

@Component({
    templateUrl: './reset-password.template.html',
    styleUrls: ['../common.style.scss', '../common.responsive.scss', './reset-password.style.scss'],
})
export class ResetPasswordView {
    readonly supportLink = AppResources.SupportTicket;
    isLoading = false;
    selection: 'forgot' | 'different' = 'forgot';
    email: string;
    sent = false;
    standardErrorFeatureFlag = false;

    constructor(
        protected auth: AuthService,
        protected notify: NotifyService,
        private featureService: FeatureService
    ) {
        this.featureService.has(FeatureFlag.standardErrorResponse).then((flagValue: boolean) => {
            this.standardErrorFeatureFlag = flagValue;
        });
    }

    reset(form: NgForm): boolean | void {
        if (form.invalid) {
            return false;
        }

        this.isLoading = true;
        this.auth
            .resetPassword({ email: this.email })
            .then(() => {
                this.isLoading = false;
                this.sent = true;
            })
            .catch((err) => {
                this.isLoading = false;
                this.onResetFailure(err);
            });
    }

    private onResetFailure(err: HttpErrorResponse): void {
        if (this.standardErrorFeatureFlag) {
            const jsonError = new JsonApiError(err);
            const invalidResponse = ErrorParser.getInvalidResponseFields(jsonError.raw.error);
            if (invalidResponse) {
                this.notify.error(invalidResponse);
                return;
            }
            const jsonErrors = jsonError.raw.error.errors;
            jsonErrors.forEach((error: JsonApiError) => {
                this.notify.error(ErrorParser.parseJsonApiError(error));
            });
            return;
        }
        this.notify.error(ErrorParser.parse(err));
    }
}
