import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StepsComponent } from '@app/components';
import { removeNullValuesFromGendersCollection } from '@app/components/add-genders/functions/remove-null-genders';
import { removeNullValuesFromPronounsCollection } from '@app/components/add-pronouns/functions/remove-null-pronouns';
import { AnalyticEvents, FeatureFlag } from '@app/enums';
import { SetupDocumentsForm, SetupEmployeeForm, SetupPersonalForm } from '@app/forms';
import { X_LANG_HEADER } from '@app/functions/x-lang';
import { Account } from '@app/models/account/account.model';
import { Address } from '@app/models/employee/address.model';
import { DataValue } from '@app/models/employee/data-value.model';
import { DataFieldsService } from '@app/modules/employees/services';
import { AbilityService, AnalyticService, AudioFileService, UserAccessService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { FeatureService } from '@app/services/feature.service';
import { NotifyService } from '@app/services/notify.service';
import { BankAccount } from '@models/employee/bank-account.model';
import { EmployeeNamePronunciation } from '@models/employee/employee-name-pronunciation.model';
import { Employee } from '@models/employee/employee.model';
import { HireQuestion } from '@models/employee/hire-question.model';
import { CompanySetting } from '@models/settings/company-setting.model';

const PAYMENT_METHOD_DIRECT_DEPOSIT = 'direct deposit';

enum Steps {
    Welcome = 0,
    Personal = 1,
    Documents = 2,
    Employment = 3,
    Survey = 4,
    GettingStarted = 5,
}

@Component({
    templateUrl: 'employee-setup.view.html',
    styleUrls: ['employee-setup.style.scss', './employee-setup.responsive.scss'],
    providers: [DataFieldsService],
})
export class EmployeeSetupView implements OnInit, OnDestroy {
    @ViewChild('stepHeader', { static: true }) stepHeader: StepsComponent | null = null;
    @ViewChild('setupPersonalForm') setupPersonalForm: SetupPersonalForm;
    @ViewChild('setupEmployeeForm') setupEmployeeForm: SetupEmployeeForm;
    @ViewChild('setupDocumentsForm') setupDocumentsForm: SetupDocumentsForm;
    @ViewChild('setupSurveyForm') setupSurveyForm: SetupDocumentsForm;

    employee: Employee;
    isLoading = true;
    step = 0;
    currentStepValid = false;
    stepData: any[] = [{}, {}, {}, {}, {}, {}];
    Steps = Steps;
    bankingInformationRequiredByDefault = false;
    bankingInformationOnboarding = false;
    disableIdentityQuestions = false;
    doHiringSurvey = false;
    enableCustomFieldsForOnboarding = false;
    isPayrollSyncEnabled = false;
    private _formSteps: any[] = [
        { step: 'welcome' },
        { step: 'personal' },
        { step: 'documents' },
        { step: 'employment' },
        { step: 'survey' },
        { step: 'getStarted' },
    ];
    private bankAccountId: number | null = null;

    constructor(
        private auth: AuthService,
        private notify: NotifyService,
        private abilities: AbilityService,
        private featureService: FeatureService,
        private dataFieldsService: DataFieldsService,
        private analyticsService: AnalyticService,
        private userAccess: UserAccessService,
        private router: Router,
        private audioFileService: AudioFileService
    ) {
        this.setFeatureFlag();
    }

    async ngOnInit(): Promise<void> {
        await this.redirectIfAlreadyOnboarded();
        this.loadCompanySettings();
        this.loadBankAccount();

        $('body').addClass('login');
        $(window).resize(() => {
            this.renderForm();
        });
        this.renderForm();
        this.employee = this.auth.employee;

        this.stepData[Steps.Welcome].welcomeMessage = this.employee.welcomeMessage;

        // Personal - basic information
        this.stepData[Steps.Personal].firstName = this.employee.firstName;
        this.stepData[Steps.Personal].lastName = this.employee.lastName;
        this.stepData[Steps.Personal].genders = this.employee.genders;
        this.stepData[Steps.Personal].pronouns = this.employee.pronouns;

        // Personal - legal information
        this.stepData[Steps.Personal].legalFirstName = this.employee.account.legalFirstName;
        this.stepData[Steps.Personal].legalMiddleName = this.employee.account.legalMiddleName;
        this.stepData[Steps.Personal].legalLastName = this.employee.account.legalLastName;

        this.stepData[Steps.Personal].bornOn = this.employee.bornOn;
        this.stepData[Steps.Personal].legalSex = this.employee.legalSex;

        this.stepData[Steps.Personal].phoneMobile = this.employee.phoneMobile;

        // Employment information
        this.stepData[Steps.Employment].sin = this.employee.sin;

        if (this.employee.collectHiringQuestions) {
            HireQuestion.param('company', this.auth.company.id)
                .all()
                .then(([onboardingQuestions]) => {
                    if (onboardingQuestions.length) {
                        this.doHiringSurvey = true;
                    }
                });

            return;
        }

        if (this.employee.address) {
            const address = this.employee.address;
            this.stepData[Steps.Personal].country = address.country;
            this.stepData[Steps.Personal].province = address.province;
            this.stepData[Steps.Personal].city = address.city;
            this.stepData[Steps.Personal].postalCode = address.postalCode;
            this.stepData[Steps.Personal].addressLine1 = address.addressLine1;
        }

        this.stepData[Steps.Employment].phoneWork = this.employee.phoneWork;
        this.stepData[Steps.Employment].ecPrimaryName = this.employee.ecPrimaryName;
        this.stepData[Steps.Employment].ecPrimaryRelation = this.employee.ecPrimaryRelation;
        this.stepData[Steps.Employment].ecPrimaryPhone = this.employee.ecPrimaryPhone;
        this.stepData[Steps.Employment].ecSecondaryName = this.employee.ecSecondaryName;
        this.stepData[Steps.Employment].ecSecondaryRelation = this.employee.ecSecondaryRelation;
        this.stepData[Steps.Employment].ecSecondaryPhone = this.employee.ecSecondaryPhone;

        this.isPayrollSyncEnabled = this.employee.isPayrollSyncEnabled;
    }

    ngOnDestroy(): void {
        $('body').removeClass('login');
    }

    goToDashboard(): void {
        window.location.href = '/dashboard';
    }

    onNextStep(): void {
        this.analyticsService.trackEvent(this.getStepAnalyticEvent(this.step));

        const currentForm = this.getCurrentStepForm();
        if (currentForm) {
            currentForm.submit();
            const isValid = currentForm.isValid;
            if (!isValid) return;
        }

        if (this.step + 1 === Steps.Survey && !this.doHiringSurvey) {
            // If no survey, call it a day
            this.step = Steps.GettingStarted;
            this.stepHeader.activeStep = this.step;
            return;
        }
        this.step = this.step + 1;
        this.stepHeader.activeStep = this.step;
    }

    onBackStep(): void {
        this.step = this.step - 1;
        this.stepHeader.activeStep = this.step;
    }

    formSteps(): any[] {
        return this._formSteps;
    }

    stepValidated(valid: boolean, data: any, step: number): void {
        this.stepData[step] = data;
        this.currentStepValid = valid;
    }

    done(): void {
        this.isLoading = true;

        Employee.param('company', this.auth.employee!.companyId)
            .with('account')
            .find(this.auth.employee!.id)
            .then((employee) => {
                // update account details
                const account: Account = employee.account;
                this.updateAccount(account);

                // update employee details
                this.updateEmployee(employee);

                this.analyticsService.trackEvent(AnalyticEvents.EmployeeOnboardingCompleteGetStarted);
            })
            .catch(() => {
                this.step = 0;
                this.notify.error('forms.setup-notify.notFound');
            });
    }

    home(): void {
        window.open('https://www.humi.ca');
    }

    register(): void {
        this.isLoading = true;
    }

    logout(event: Event): void {
        event.preventDefault();
        this.userAccess.logout();
    }

    getCurrentStepForm(): SetupPersonalForm | SetupEmployeeForm | SetupDocumentsForm | null {
        if (this.step === Steps.Personal) {
            return this.setupPersonalForm;
        }
        if (this.step === Steps.Employment) {
            return this.setupEmployeeForm;
        }
        if (this.step === Steps.Documents) {
            return this.setupDocumentsForm;
        }
        if (this.step === Steps.Survey) {
            return this.setupSurveyForm;
        }

        return null;
    }

    /**
     * Adjust the login form to center window
     * Ensure body has classes related to login
     */
    private renderForm(): void {
        setTimeout(() => {
            $('body').addClass('login');
            $('.ui.checkbox').checkbox();
            $('[name="email"]').focus();
            this.isLoading = false;
        });
    }

    private async updateAccount(account: Account): Promise<void> {
        const personalData = this.stepData[Steps.Personal];

        try {
            account.legalFirstName = personalData.legalFirstName;
            account.legalMiddleName = personalData.legalMiddleName;
            account.legalLastName = personalData.legalLastName;

            await account.save();
        } catch (err) {
            this.notify.error(err.message || 'forms.setup-notify.accountNotUpdated');
        }
    }

    private updateEmployee(employee: Employee): void {
        const employeeData = this.stepData[Steps.Employment];
        const personalData = this.stepData[Steps.Personal];

        employee.ecPrimaryName = employeeData.ecPrimaryName;
        employee.ecPrimaryRelation = employeeData.ecPrimaryRelation;
        employee.ecPrimaryPhone = employeeData.ecPrimaryPhone;
        employee.ecSecondaryName = employeeData.ecSecondaryName;
        employee.ecSecondaryRelation = employeeData.ecSecondaryRelation;
        employee.ecSecondaryPhone = employeeData.ecSecondaryPhone;
        employee.bankAccount.institutionNumber = employeeData.bankInstitution;
        employee.bankAccount.branchNumber = employeeData.bankBranch;
        employee.bankAccount.accountNumber = employeeData.bankAccount;
        employee.sin = employeeData.sin;

        employee.firstName = personalData.firstName;
        employee.lastName = personalData.lastName;
        employee.bornOn = personalData.bornOn;
        employee.isBirthdayPrivate = personalData.isBirthdayPrivate;
        employee.legalSex = personalData.legalSex;
        employee.phoneMobile = personalData.phoneMobile;

        employee.genders = removeNullValuesFromGendersCollection(personalData.genders);
        employee.pronouns = removeNullValuesFromPronounsCollection(personalData.pronouns);

        employee.language = localStorage.getItem(X_LANG_HEADER);

        const validBankDetails = Boolean(
            employee.bankAccount &&
                employee.bankAccount.institutionNumber &&
                employee.bankAccount.branchNumber &&
                employee.bankAccount.accountNumber
        );

        if (validBankDetails) {
            employee.paymentMethod = PAYMENT_METHOD_DIRECT_DEPOSIT;
        }

        employee
            .param('company', this.auth.employee!.companyId)
            .save()
            .then((newEmployee) => {
                // Pendo events for genders and pronouns
                if (employee.genders?.length) {
                    this.analyticsService.trackEvent(AnalyticEvents.GendersAddedDuringOnboardingFlow);
                }

                if (employee.pronouns?.length) {
                    this.analyticsService.trackEvent(AnalyticEvents.PronounsAddedDuringOnboardingFlow);
                }

                if (validBankDetails) {
                    this.createBankAccount(newEmployee);
                }
                this.createEmployeeAddress(newEmployee);

                this.saveEmployeeNamePronunciation();
            })
            .catch((err) => {
                this.isLoading = false;
                this.step = 0;
                this.errorCleanup();
                this.notify.error(err.message || 'forms.setup-notify.employeeNotUpdated');
            });
    }

    private createBankAccount(employee: Employee): void {
        const employeeData = this.stepData[Steps.Employment];
        const bankAccountInfo: any = {
            institutionNumber: employeeData.bankInstitution,
            branchNumber: employeeData.bankBranch,
            accountNumber: employeeData.bankAccount,
        };

        if (this.bankAccountId) {
            bankAccountInfo.id = this.bankAccountId;
        }

        new BankAccount(bankAccountInfo)
            .param('company', this.auth.company.id)
            .param('employee', employee.id)
            .save()
            .then((bankAccount: BankAccount) => {
                this.bankAccountId = bankAccount.id;
            })
            .catch((err) => {
                this.isLoading = false;
                this.step = 0;
                this.errorCleanup();
                this.notify.error(err.message || 'forms.setup-notify.bankAccountNotUpdated');
            });
    }

    private createEmployeeAddress(employee: Employee): void {
        const personalData = this.stepData[Steps.Personal];

        let address = new Address({
            addressLine1: personalData.addressLine1,
            city: personalData.city,
            province: personalData.province,
            country: personalData.country,
            postalCode: personalData.postalCode,
        });

        if (this.auth.employee.address) {
            address = this.auth.employee.address;
            address.addressLine1 = personalData.addressLine1;
            address.city = personalData.city;
            address.province = personalData.province;
            address.country = personalData.country;
            address.postalCode = personalData.postalCode;
        }

        address
            .param('company', employee.companyId)
            .param('employee', employee.id)
            .save()
            .then((address) => {
                employee.addressId = address.id;

                this.updateDataFieldValues()
                    .then(() => {
                        this.setEmployeeStatusToActive(employee);
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.step = 0;
                        this.errorCleanup();
                        this.notify.error(err.message || 'forms.setup-notify.customDataNotSaved');
                        return;
                    });
            })
            .catch((err) => {
                this.isLoading = false;
                this.step = 0;
                this.errorCleanup();
                this.notify.error(err.message || 'forms.setup-notify.addressNotCreated');
            });
    }

    private setEmployeeStatusToActive(employee: Employee): void {
        employee.status = 'active';
        employee
            .param('company', this.auth.employee!.companyId)
            .save()
            .then(async () => {
                // notify admins of custom data fields they need to fill for this employee
                if (this.enableCustomFieldsForOnboarding) {
                    await this.notifyAdminsOfCustomDataFieldsToFill();
                }
                this.saveSurvey();
            })
            .catch((err) => {
                this.isLoading = false;
                this.step = 0;
                this.errorCleanup();
                this.notify.error(err.message || 'forms.setup-notify.employeeStatusNotUpdated');
            });
    }

    private saveSurvey(): void {
        if (!this.doHiringSurvey) {
            this.notify.success('forms.setup-notify.informationSaved');

            // We done
            this.goToDashboard();
            return;
        }

        const surveyData = this.stepData[Steps.Survey];

        // save survey
        const answers = [];
        for (const question of Object.keys(surveyData)) {
            answers.push(surveyData[question].save());
        }

        Promise.all(answers)
            .then(() => {
                this.notify.success('forms.setup-notify.informationSaved');

                // We done
                this.goToDashboard();
            })
            .catch((err) => {
                this.isLoading = false;
                this.step = 0;
                this.errorCleanup();
                this.notify.error(err.message || 'forms.setup-notify.surveyNotSaved');
            });
    }

    private async notifyAdminsOfCustomDataFieldsToFill(): Promise<void> {
        await this.dataFieldsService.notifyAdminToFillDataFields();
    }

    private updateDataFieldValues(): Promise<any | void> {
        if (
            this.enableCustomFieldsForOnboarding &&
            (this.stepData[Steps.Personal].customDataFieldValues?.length > 0 ||
                this.stepData[Steps.Employment].customDataFieldValues?.length)
        ) {
            const profileDataValuePromises = this.saveCustomDataFieldValues(
                this.stepData[Steps.Personal].customDataFieldValues
            );
            const employmentDataValuePromises = this.saveCustomDataFieldValues(
                this.stepData[Steps.Employment].customDataFieldValues
            );
            return Promise.all([profileDataValuePromises, employmentDataValuePromises]);
        }

        return Promise.resolve();
    }

    private errorCleanup(): void {}

    private loadCompanySettings(): void {
        // Banking information is required if the employee uses payroll
        if (this.abilities.payroll() && this.auth.employee.isPayrollSyncEnabled) {
            this.bankingInformationRequiredByDefault = true;
            this.bankingInformationOnboarding = true;

            return;
        }

        CompanySetting.param('company', this.auth.company!.id)
            .where('module', 'EmployeeManagement')
            .with('setting')
            .get()
            .then(([companySettings]) => {
                this.bankingInformationRequiredByDefault = companySettings.find(
                    (setting: CompanySetting) => setting.setting.name === 'bankingInformation.required'
                ).value;
                this.bankingInformationOnboarding = companySettings.find(
                    (setting) => setting.setting.name === 'bankingInformation.collectDuringOnboarding'
                ).value;
                this.disableIdentityQuestions =
                    companySettings.find((setting) => setting.setting.name === 'removePronounAndGenderFromOnboarding')
                        ?.value ?? this.disableIdentityQuestions;
            })
            .catch((err) => this.notify.error(err.message || 'forms.setup-notify.couldNotLoadSettings'));
    }

    private loadBankAccount(): void {
        if (this.auth.employee!.bankAccountId) {
            this.bankAccountId = this.auth.employee!.bankAccountId;
            BankAccount.param('company', this.auth.company.id)
                .param('employee', this.auth.employee!.id)
                .find(this.bankAccountId)
                .then((bankAccount) => {
                    this.stepData[Steps.Employment].bankInstitution = bankAccount.institutionNumber;
                    this.stepData[Steps.Employment].bankBranch = bankAccount.branchNumber;
                    this.stepData[Steps.Employment].bankAccount = bankAccount.accountNumber;
                });
        }
    }

    private saveCustomDataFieldValues(customDataFieldValues: DataValue[]): Promise<DataValue>[] {
        return customDataFieldValues
            .filter((dataValue: DataValue) => {
                // Check that changes made to data value
                if (dataValue.isDirty()) {
                    return true;
                }
            })
            .map((dataValue: DataValue) => {
                dataValue.employeeId = this.auth.employee.id;
                return dataValue.param('company', this.auth.company.id).save();
            });
    }

    private saveEmployeeNamePronunciation(): void {
        const audioBlob = this.stepData[Steps.Personal].audioBlob;
        const supportedAudioFormat = this.stepData[Steps.Personal].supportedAudioFormat;

        if (audioBlob && supportedAudioFormat) {
            this.audioFileService.store(audioBlob, supportedAudioFormat).then((file) => {
                const pronunciation = new EmployeeNamePronunciation();
                pronunciation.fileId = file.id;
                pronunciation
                    .param('company', this.employee.companyId)
                    .param('employee', this.employee.id)
                    .create()
                    .then(() => {
                        this.analyticsService.trackEvent(AnalyticEvents.NamePronunciationOnboardingCreaeted);
                    });
            });
        }
    }

    private async setFeatureFlag(): Promise<void> {
        this.enableCustomFieldsForOnboarding = await this.featureService.has(FeatureFlag.customFieldsForOnboarding);
    }

    private getStepAnalyticEvent(stepNumber: number): AnalyticEvents {
        switch (stepNumber) {
            case Steps.Welcome:
                return AnalyticEvents.EmployeeOnboardingAcceptTerms;
            case Steps.Personal:
                return AnalyticEvents.EmployeeOnboardingCompletePersonalInfo;
            case Steps.Documents:
                return AnalyticEvents.EmployeeOnboardingReviewedDocuments;
            case Steps.Employment:
                return AnalyticEvents.EmployeeOnboardingCompleteEmploymentInfo;
            case Steps.Survey:
                return AnalyticEvents.EmployeeOnboardingCompleteSurvey;
        }
    }

    private async redirectIfAlreadyOnboarded(): Promise<void> {
        if (this.auth.employee.status !== 'active') {
            return;
        }

        this.auth.redirectUrl = '';
        await this.router.navigate(['/dashboard']);
    }
}
