<div class="wrapper">
    <app-humi-logo size="medium" labelOrientation="horizontal" />
    <h1>An Error Occurred</h1>
    <div *ngIf="isErrors" class="snack-bar-message">
        <span class="error">{{ errorMessage }}</span>
    </div>
    <p>We track these errors automatically, but if the problem persists feel free to contact us.</p>
    <ui-button *ngIf="!isErrors" type="primary" [url]="'/'">Return Home</ui-button>
    <ui-button *ngIf="isErrors" type="primary" [url]="'/auth0login'">Return to Login</ui-button>
</div>
