<div class="page-container centered-both-axes">
    <!-- Form -->
    <ui-card [withMargin]="false">
        <app-humi-logo class="logo" />
        <ui-flag-deprecated
            *ngIf="accountStatusError"
            icon="Exclamation"
            iconType="danger"
            [header]="accountStatusError!.header"
        >
            <p class="flag-content" [innerHTML]="accountStatusError!.body"></p>
        </ui-flag-deprecated>

        <ui-flag-deprecated
            *ngIf="showAttemptsWarning"
            icon="Exclamation"
            [closeable]="true"
            (close)="closeWarning()"
            iconType="danger"
            [header]="'login.loginAttemptsRemaining' | translate: { attemptsRemaining: attemptsRemaining }"
        >
            <p class="flag-content" [innerHTML]="'login.securityLockLoginAttempts' | translate"></p>
        </ui-flag-deprecated>
        <form #form="ngForm">
            <mat-form-field>
                <mat-label>{{ 'login.email' | translate }}</mat-label>
                <input matInput [formControl]="email" tabindex="1" required name="email" type="text" />
                <mat-error *ngIf="email.invalid">{{ email.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ 'login.password' | translate }} </mat-label>
                <a class="forgotLink" tabindex="6" [routerLink]="['/reset']">{{
                    'login.forgotPassword' | translate
                }}</a>
                <input
                    matInput
                    [formControl]="password"
                    class="password"
                    tabindex="2"
                    required
                    name="password"
                    [type]="showPassword ? 'text' : 'password'"
                />
                <mat-icon
                    matSuffix
                    (click)="toggleShowPassword()"
                    [svgIcon]="showPassword ? 'visibilityEnabled' : 'visiblityDisabled'"
                ></mat-icon>
                <mat-error *ngIf="password.invalid">{{ password.errors | formErrors }}</mat-error>
            </mat-form-field>

            <ui-button submit (click)="login()" tabIndexValue="3" type="primary"
                >{{ 'login.signIn' | translate }}
            </ui-button>

            <div class="divider">
                <span>{{ 'or' | translate }}</span>
            </div>

            <div class="buttons-container">
                <ui-button (click)="googleOauth($event)" tabIndexValue="4" type="outline">
                    <img [src]="googleLogo" /><span class="hide-small">{{ 'login.signInWithGoogle' | translate }}</span
                    ><span class="only-small">{{ 'login.google' | translate }}</span>
                </ui-button>
                <ui-button (click)="microsoftOauth($event)" tabIndexValue="5" type="outline">
                    <img [src]="microsoftLogo" /><span class="hide-small">{{
                        'login.signInWithMicrosoft' | translate
                    }}</span
                    ><span class="only-small">{{ 'login.microsoft' | translate }}</span>
                </ui-button>
            </div>
        </form>
    </ui-card>
    <div class="additional-links">
        <p>
            {{ 'login.needHelp' | translate }}
            <a [href]="helpLink" target="_blank">{{ 'login.visitOurHelpCenter' | translate }}</a>
        </p>
        <p>
            <a [href]="salesLink" target="_blank">{{ 'login.wantToSignYourCompanyUpWithHumi' | translate }}</a>
        </p>
    </div>
</div>
