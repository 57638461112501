import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services';

@Component({
    templateUrl: './activate-router.template.html',
    styleUrl: './activate-router.style.scss',
})
export class ActivateRouterView {
    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        // Get token from query param and then remove it from the browser URL
        this.authService.activationToken = this.route.snapshot.queryParams['token'];
        this.router.navigate([], { replaceUrl: true });
        if (!this.authService.activationToken) {
            this.router.navigate(['activateInvitation', 'error']);
        }
    }

    /**
     * If the user tries to navigate away we should warn about loss of state
     */
    @HostListener('window:beforeunload', ['$event'])
    warn(event: BeforeUnloadEvent): void {
        // We don't warn if they
        // A) Don't have an activation token (meaning they're on an error screen)
        // B) Have an accountId, meaning they've exchanged their activation token for a session token
        if (this.authService.activationToken && !this.authService.accountId) {
            event.preventDefault();
        }
    }
}
