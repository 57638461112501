import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { Platform } from '@app/classes';
import { AuthGuard } from '@app/guards/auth.guard';
import { GuestGuard } from '@app/guards/guest.guard';
import { ModulesGuard } from '@app/guards/modules.guard';
import { PermissionGuard } from '@app/guards/permission.guard';
import {
    ActivateErrorView,
    ActivateRouterView,
    LoginView,
    OfferLetterSignView,
    ResetPasswordView,
    StorePasswordView,
} from '@app/views';
import { ClientErrorView } from '@app/views/client-error/client-error.view';
import { SetupView } from '@app/views/company-onboarding/setup/setup.view';
import { DebugView } from '@app/views/debug/debug.view';
import { ActivateView } from '@app/views/employee-onboarding/activate/activate.view';
import { EmployeeSetupView } from '@app/views/employee-onboarding/employee-setup/employee-setup.view';
import { ForbiddenView } from '@app/views/forbidden/forbidden.view';
import { NotFoundView } from '@app/views/not-found/not-found.view';
import { OAuthRedirectView } from '@app/views/oauth-redirect/oauth-redirect.view';
import { ServerErrorView } from '@app/views/server-error/server-error.view';
import { VerifyEmailView } from '@app/views/verify-email/verify-email.view';
import { XeroCallbackView } from '@payroll/views/xero/callback/xero-callback.view';
import { Auth0Component } from './components/auth0/auth0.view';
import { FeatureFlag } from './enums';
import { FeatureGuard, HasFeatureRedirectGuard } from './guards';
import { CompanyModuleGuard } from './guards/company-module.guard';
import { MultiCompanyRedirectGuard } from './guards/multi-company-redirect.guard';
import { RoutesWithOptions } from './interfaces';
import { ReportingModuleMenuService } from './modules/reporting/services/reporting-module-menu.service';
import { PayrollHighlightsView } from './modules/self-serve/views/highlights/payroll/payroll.view';

const routes: RoutesWithOptions = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },
    {
        path: 'guest/offer-letter/:offerLetterUuid/sign',
        component: OfferLetterSignView,
    },
    {
        path: 'verifyEmail',
        component: VerifyEmailView,
    },
    {
        path: 'login',
        canActivate: [GuestGuard, HasFeatureRedirectGuard],
        component: LoginView,
        data: {
            feature: {
                flags: [FeatureFlag.auth0Redirect],
                redirectTo: ['/auth0login'],
            },
            hideSidebar: true,
            hideTopbar: true,
        },
    },
    {
        path: 'auth0login',
        component: Auth0Component,
        data: { auth0Action: 'LOGIN', hideSidebar: true, hideTopbar: true },
    },
    {
        path: 'logout',
        canActivate: [AuthGuard],
        component: Auth0Component,
        data: { auth0Action: 'LOGOUT', hideSidebar: true, hideTopbar: true },
    },
    {
        path: 'auth0callback',
        component: Auth0Component,
        data: { auth0Action: 'CALLBACK', hideSidebar: true, hideTopbar: true },
    },
    {
        path: 'OAuthLogin',
        canActivate: [GuestGuard],
        component: OAuthRedirectView,
    },
    {
        path: 'reset',
        canActivate: [GuestGuard],
        component: ResetPasswordView,
    },
    // The following two routes have been nested under the activation flow.
    // This allows them to share a nested view and functionality
    // The old routes now automatically redirect to the nested routes
    {
        path: 'resetPassword',
        redirectTo: 'activateInvitation/resetPassword',
    },
    {
        path: 'setupEmployee',
        redirectTo: 'activateInvitation/setupEmployee',
    },
    {
        path: 'activateInvitation',
        component: ActivateRouterView,
        children: [
            {
                path: '',
                component: ActivateView,
                canActivate: [GuestGuard],
                data: {
                    hideSidebar: true,
                    hideTopbar: true,
                },
            },
            {
                path: 'resetPassword',
                canActivate: [GuestGuard],
                component: StorePasswordView,
                data: {
                    hideSidebar: true,
                    hideTopbar: true,
                },
            },
            {
                path: 'setupEmployee',
                canActivate: [AuthGuard],
                component: EmployeeSetupView,
                data: {
                    hideSidebar: true,
                    hideTopbar: true,
                },
            },
            {
                path: 'error',
                component: ActivateErrorView,
                canActivate: [GuestGuard],
                data: {
                    hideSidebar: true,
                    hideTopbar: true,
                },
            },
        ],
    },
    {
        path: 'setup',
        canActivate: [AuthGuard],
        component: SetupView,
        data: {
            hideSidebar: true,
            hideTopbar: true,
        },
    },
    {
        path: 'debug',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: DebugView,
            },
        ],
    },
    {
        path: '',
        canLoad: [AuthGuard, ModulesGuard],
        children: [
            {
                path: '',
                canMatch: [MultiCompanyRedirectGuard.canMatch],
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'profile',
                        loadChildren: () => import('@account/account.module').then((m) => m.AccountModule),
                    },
                    {
                        path: 'account',
                        loadChildren: () => import('@account/account.module').then((m) => m.AccountModule),
                    },

                    {
                        path: 'company',
                        loadChildren: () => import('@company/company.module').then((m) => m.CompanyModule),
                    },
                    {
                        path: 'documents',
                        loadChildren: () => import('@document/document.module').then((m) => m.DocumentModule),
                    },
                    {
                        path: 'documents-v2',
                        canActivate: [FeatureGuard],
                        loadChildren: () =>
                            import('@documents-v2/documents-v2.module').then((m) => m.DocumentsV2Module),
                        data: { feature: { flags: [FeatureFlag.documentsV2] } },
                    },
                    {
                        path: 'dashboard',
                        loadChildren: () => import('@dashboard/dashboard.module').then((m) => m.DashboardModule),
                    },
                    {
                        // route is different than module name because route is part of UI
                        // and `self-serve` is not nice UI
                        path: 'company-onboarding',
                        loadChildren: () => import('@self-serve/self-serve.module').then((m) => m.SelfServeModule),
                    },
                    {
                        path: 'reporting',
                        canActivate: [PermissionGuard],
                        providers: [ReportingModuleMenuService],
                        loadChildren: () => import('@reporting/reporting.module').then((m) => m.ReportingModule),
                        data: { permission: 'accessReporting' },
                    },
                    {
                        path: 'settings',
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('@settings/settings.module').then((m) => m.SettingsModule),
                        data: { permission: 'accessSettings' },
                    },
                    {
                        path: 'employees',
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('@employees/employees.module').then((m) => m.EmployeesModule),
                        data: { permission: 'viewPeople' },
                    },
                    {
                        path: 'recruiting',
                        canActivate: [PermissionGuard],
                        loadChildren: () =>
                            import('@applicant-tracker/applicant-tracker.module').then((m) => m.ApplicantTrackerModule),
                        data: { permission: ['accessRecruiting', Platform.recruiting.permission.access] },
                    },
                    {
                        path: 'time-off/v3',
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('@time-off-v3/time-off-v3.module').then((m) => m.TimeOffV3Module),
                        data: { permission: 'accessTimeOff' },
                    },
                    {
                        /**
                         * This only exists to make routerLink better,
                         * maybe at some point should be its own module
                         */
                        path: 'calendar',
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('@time-off-v3/time-off-v3.module').then((m) => m.TimeOffV3Module),
                        data: { permission: 'accessTimeOff' },
                    },

                    {
                        path: 'benefits',
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('@benefits/benefits.module').then((m) => m.BenefitsModule),
                        data: { permission: 'accessBenefits' },
                    },
                    {
                        path: 'change-requests',
                        loadChildren: () =>
                            import('@change-requests/change-requests.module').then((m) => m.ChangeRequestsModule),
                    },
                    {
                        path: 'payroll',
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('@payroll/payroll.module').then((m) => m.PayrollModule),
                        data: {
                            permission: 'accessPayroll',
                        },
                    },
                    {
                        // I have moved this outside of the self serve module so that our sidenav
                        // won't consider this active any time you are in the `/company-onboarding` views.
                        path: 'company-onboarding-payroll-highlights',
                        component: PayrollHighlightsView,
                    },
                    {
                        path: 'performance',
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('@performance/performance.module').then((m) => m.PerformanceModule),
                        data: { permission: 'accessPerformance' },
                    },
                    {
                        path: 'surveys',
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('@performance/performance.module').then((m) => m.PerformanceModule),
                        data: { permission: 'accessPerformance' },
                    },
                    {
                        path: 'add-ons',
                        canActivate: [PermissionGuard, FeatureGuard],
                        loadChildren: () => import('@upsell/upsell.module').then((m) => m.UpsellModule),
                        data: { permission: 'administrate', feature: { flags: [FeatureFlag.upsell] } },
                    },
                    {
                        path: 'time-tracking',
                        canActivate: [CompanyModuleGuard],
                        loadChildren: () =>
                            import('@time-tracking/time-tracking.module').then((m) => m.TimeTrackingModule),
                        data: {
                            companyModule: Platform.modules.timeTracking,
                        },
                    },
                    {
                        path: 'training',
                        loadChildren: () => import('@training/training.module').then((m) => m.TrainingModule),
                    },
                    {
                        path: 'backgroundJobs',
                        loadChildren: () =>
                            import('@async-system-tasks/async-system-tasks.module').then(
                                (m) => m.AsyncSystemTasksModule
                            ),
                    },
                    {
                        path: 'videos',
                        loadChildren: () => import('@videos/videos.module').then((m) => m.VideosModule),
                    },

                    {
                        path: 'xero/auth/callback',
                        component: XeroCallbackView,
                        data: { permission: 'accessSettings' },
                    },
                ],
            },
        ],
    },
    { path: 'forbidden', component: ForbiddenView },
    { path: 'server-error', component: ServerErrorView },
    { path: 'client-error', component: ClientErrorView },
    { path: '**', component: NotFoundView },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRouterModule {}
