<div class="ui basic login segment" [class.loading]="isLoading">
    <a href="https://www.humi.ca" target="_blank" class="logo">
        <app-humi-logo labelOrientation="horizontal" />
    </a>

    <div class="container">
        <app-steps #stepHeader [steps]="formSteps()"></app-steps>
        <ui-banner variant="danger" class="margin-bottom-1" *ngIf="errorMessage">{{ errorMessage }}</ui-banner>
        <ng-container [ngSwitch]="step">
            <div class="ui segment" *ngSwitchCase="0">
                <app-form-setup-company
                    (valid)="stepValidated($event.valid, $event.data, 0)"
                    [formData]="stepData[0]"
                ></app-form-setup-company>
            </div>

            <div class="ui segment" *ngSwitchCase="1">
                <app-form-setup-admin
                    (valid)="stepValidated($event.valid, $event.data, 1)"
                    [formData]="stepData[1]"
                ></app-form-setup-admin>
            </div>

            <div class="ui segment" *ngSwitchCase="2">
                <app-form-setup-payment
                    (valid)="stepValidated($event.valid, $event.data, 2)"
                    [formData]="stepData[0]"
                ></app-form-setup-payment>
            </div>

            <div class="ui segment" *ngSwitchCase="3">
                <app-form-setup-complete
                    (valid)="stepValidated($event.valid, $event.data, 3)"
                    [formData]="stepData[3]"
                ></app-form-setup-complete>

                <ui-button
                    (click)="goToDashboard()"
                    type="primary"
                    style="display: block; width: 265px; margin: auto"
                    [class.disabled]="!currentStepValid"
                    >Take Me To My Home Screen</ui-button
                >
            </div>
        </ng-container>

        <div *ngIf="step < 3" class="step-buttons">
            <ui-button type="ghost" [disabled]="step === 0" (click)="onBackStep()"
                ><mat-icon svgIcon="chevronLeft" /> Previous
            </ui-button>
            <ui-button type="primary" [disabled]="!currentStepValid" (click)="onNextStep()"
                >Next <mat-icon svgIcon="chevronRight"
            /></ui-button>
        </div>
    </div>
</div>
