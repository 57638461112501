import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { NotifyService } from '@app/services/notify.service';
import { StepsComponent } from '@app/components';
import { Company } from '@models/company/company.model';
import { Office } from '@models/company/office.model';
import { Employee } from '@models/employee/employee.model';
import { Card } from '@models/settings/billing/card.model';

@Component({
    templateUrl: 'setup.view.html',
    styleUrls: ['setup.style.scss'],
})
export class SetupView implements OnInit, OnDestroy {
    @ViewChild('stepHeader', { static: true }) stepHeader: StepsComponent;
    isLoading = true;
    step = 0;
    currentStepValid = false;
    stepData: any[] = [{}, {}, {}, {}, {}];
    errorMessage: string | null;
    private _formSteps: any[] = [
        { step: 'Company' },
        { step: 'Administrator' },
        { step: 'Payment' },
        { step: 'Get Started' },
    ];

    constructor(
        public auth: AuthService,
        private notify: NotifyService
    ) {}

    ngOnInit(): void {
        $('body').addClass('login');
        $(window).resize(() => {
            this.renderForm();
        });
        Company.with('reps')
            .find(this.auth.user.companyId)
            .then((company) => {
                this.stepData[0] = company;
                this.stepData[3] = company;

                Employee.param('company', this.auth.user.companyId)
                    .find(this.auth.user.id)
                    .then((employee) => {
                        employee.firstName = this.auth.account.legalFirstName;
                        employee.lastName = this.auth.account.legalLastName;

                        this.stepData[1] = employee;
                        this.renderForm();
                    })
                    .catch();
            })
            .catch();
    }

    ngOnDestroy(): void {
        $('body').removeClass('login');
    }

    onNextStep(): void {
        if (this.step === 2) {
            this.done();
        }

        if (this.stepData[0].fullAddress) {
            this.stepData[2].province = this.stepData[0].fullAddress.administrative_area_level_1;
        }

        this.step = this.step + 1;
        this.stepHeader.activeStep = this.step;
    }

    onBackStep(): void {
        this.step = this.step - 1;
        this.stepHeader.activeStep = this.step;
    }

    formSteps(): any[] {
        return this._formSteps;
    }

    stepValidated(valid: boolean, data: any, step: number): void {
        this.stepData[step] = data;
        this.currentStepValid = valid;
    }

    done(): void {
        this.isLoading = true;
        this.errorMessage = null;

        this.updateCompany()
            .then((company) => this.createCompanyAddress(company))
            .then((company) => this.updateAdmin(company))
            .then((company) => this.createTrial(company))
            .then(() => (this.isLoading = false))
            .catch(({ err, step }) => {
                if (step) {
                    this.step = step;
                }
                this.errorMessage = err.message;
                this.notify.error('Error');

                this.isLoading = false;
            });
    }

    goToDashboard(): void {
        window.location.href = '/dashboard';
    }

    /**
     * Adjust the login form to center window
     * Ensure body has classes related to login
     */
    private renderForm(): void {
        setTimeout(() => {
            $('body').addClass('login');
            $('.ui.basic.login.segment').height($(window).height());
            $('.ui.checkbox').checkbox();
            $('[name="email"]').focus();
            this.isLoading = false;
        });
    }

    private updateCompany(): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const companyData = this.stepData[0];
            companyData.doingBusinessAsName = companyData.name;

            if (!companyData.prTenantId) {
                delete companyData.prTenantId;
            }
            if (!companyData.prCompanyId) {
                delete companyData.prCompanyId;
            }

            companyData
                .save()
                .then((company) => {
                    resolve(company);
                })
                .catch(() => {
                    reject('Could not update company');
                });
        });
    }

    private createCompanyAddress(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const office = new Office({
                name: this.stepData[0]['address'],
                addressLine1: this.stepData[0]['address'],
                city: this.stepData[0]['city'],
                province: this.stepData[0]['province'],
                country: this.stepData[0]['country'],
                postalCode: this.stepData[0]['postalCode'],
            });

            office
                .param('company', this.auth.user.companyId)
                .save()
                .then(() => {
                    resolve(company);
                })
                .catch(() => {
                    reject('Could not create company office');
                });
        });
    }

    private updateAdmin(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const employeeData = this.stepData[1];
            employeeData.status = 'active';
            employeeData.employmentType = 'full-time';
            employeeData
                .param('company', this.auth.user.companyId)
                .save()
                .then(() => resolve(company))
                .catch(() => reject('Could not update employee'));
        });
    }

    private createTrial(company: Company): Promise<Company> {
        return new Promise<Company>((resolve, reject) => {
            const trialData = this.stepData[0];
            const card = new Card({
                token: trialData.token,
            });

            card.save()
                .then((_) => {
                    resolve(company);
                })
                .catch((err) => reject({ err, step: 2 }));
        });
    }
}
