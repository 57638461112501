<form class="ui form" #form="ngForm" autocomplete="off">
    <p>Please review the cost breakdown below. Provide payment information to continue account setup.</p>

    <div class="ui divider"></div>

    <table *ngIf="invoiceLineItems?.length" class="ui table">
        <thead>
            <tr>
                <th>Module</th>
                <th></th>
                <th class="right aligned">Pricing</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of invoiceLineItems; let index = index">
                <tr *ngIf="item['amount'] > 0">
                    <td>{{ item['description'] }}</td>
                    <td></td>
                    <td class="right aligned">{{ item['amount'] / 100 | currency }}</td>
                </tr>
            </ng-container>
        </tbody>
        <tfoot>
            <tr *ngIf="dueNowSubtotal">
                <td></td>
                <td class="right aligned">Due Now</td>
                <td class="right aligned">{{ dueNowSubtotal / 100 | currency }}*</td>
            </tr>
            <tr *ngIf="subscription?.status === 'trialing'">
                <td></td>
                <td class="right aligned">Trial Ends</td>
                <td class="right aligned">{{ subscription?.trialEnd | appDate }}</td>
            </tr>
            <tr *ngIf="monthlySubtotal">
                <td></td>
                <td class="right aligned">Monthly Subtotal</td>
                <td class="right aligned">{{ monthlySubtotal / 100 | currency }}*</td>
            </tr>

            <tr *ngIf="percentDiscount">
                <td></td>
                <td class="right aligned">Discount</td>
                <td class="right aligned">{{ percentDiscount }}%</td>
            </tr>

            <tr *ngIf="dollarDiscount && !percentDiscount">
                <td></td>
                <td class="right aligned">Monthly Discount</td>
                <td class="right aligned">{{ dollarDiscount / 100 | currency }}</td>
            </tr>

            <tr *ngIf="monthlySubtotal">
                <td></td>
                @if (percentDiscount) {
                    <td class="right aligned"><strong>Monthly Total After Discount</strong></td>
                    <td class="right aligned">
                        <strong>{{ (monthlySubtotal * (1 - percentDiscount / 100)) / 100 | currency }}</strong>
                    </td>
                } @else if (dollarDiscount) {
                    <td class="right aligned"><strong>Monthly Total After Discount</strong></td>
                    <td class="right aligned">
                        <strong>{{ (monthlySubtotal - dollarDiscount) / 100 | currency }}</strong>
                    </td>
                } @else {
                    <td class="right aligned"><strong>Monthly Total</strong></td>
                    <td class="right aligned">
                        <strong>{{ monthlySubtotal / 100 | currency }}</strong>
                    </td>
                }
            </tr>

            <tr>
                <td></td>
                <td class="right aligned"></td>
                <td class="right aligned"><small>*Plus Applicable Taxes</small></td>
            </tr>
        </tfoot>
    </table>

    <div class="ui divider"></div>

    <div class="ui segment" *ngIf="!invoiceLineItems?.length && !isLoading">
        <mat-icon svgIcon="info"></mat-icon>&nbsp;&nbsp;&nbsp; We cannot display custom pricing. Please contact your
        account representatives for more details.
    </div>

    <div class="ui segment" [class.loading]="isLoading" [class.disabled]="isLoading">
        <ng-container *ngIf="!formValid">
            <div id="card-element"></div>
            <div id="card-errors"></div>
        </ng-container>
        <ng-container *ngIf="formValid">
            <mat-icon svgIcon="check"></mat-icon>
            <strong>Payment method saved</strong>
        </ng-container>
    </div>

    <ui-button type="primary" style="min-width: 225px" (click)="createToken()" [disabled]="formValid || isLoading"
        >Save Payment Method</ui-button
    >

    <div class="ui hidden divider"></div>
</form>
