<header>
    <a href="https://www.humi.ca" target="_blank" class="logo">
        <app-humi-logo labelOrientation="horizontal" />
    </a>
</header>
<div class="page-container centered both-axes" [class.loading]="isLoading">
    <ui-card>
        @if (verified) {
            <h2 level="1">{{ 'verifyEmail.emailHasBeenVerified' | translate }}</h2>
            <ui-button type="link" (click)="viewAccount()">{{ 'verifyEmail.viewAccount' | translate }}</ui-button>
        } @else {
            <h2 level="1">{{ 'verifyEmail.emailCouldNotBeVerified' | translate }}</h2>
            <div>
                <div>{{ 'verifyEmail.linkExpiredOrInvalid' | translate }}</div>
                <div>{{ 'verifyEmail.haveYouAlreadyVerified' | translate }}</div>
            </div>
            <ui-button type="link" [url]="'/login'">{{ 'verifyEmail.login' | translate }}</ui-button>
        }
    </ui-card>
</div>
