import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorParser } from '@app/classes';
import { JsonApiError } from '@app/errors/json-api.error';
import { NotifyService } from '@app/services';
import { AuthService, type AuthType } from '@app/services/auth.service';

declare let localStorage: Storage;

/**
 * OAuthRedirectView is responsible for sending the exchange token
 * to the server, waiting for a response, setting the user on the client
 * and redirecting to the dashboard.
 *
 * This view only exists so that we have a URL to hit for exchange tokens.
 *
 * Exchange tokens include sso, impersonation, etc.
 */
@Component({
    template: ` <div style="height: 100vh;" class="ui segment" [class.loading]="isLoading"></div> `,
})
export class OAuthRedirectView implements OnInit {
    isLoading = true;

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private notify: NotifyService
    ) {}

    ngOnInit(): void {
        const type: AuthType = this.route.snapshot.queryParams['type'].toUpperCase();
        const response = this.route.snapshot.queryParams['response'];

        const oAuthResponse = JSON.parse(decodeURIComponent(response));
        const errors = oAuthResponse?.errors;
        if (errors) {
            this.notify.error(ErrorParser.parseJsonApiError(errors));
            this.router.navigate(['login']);
            return;
        }
        const exchangeToken = oAuthResponse?.data?.attributes?.exchangeToken;

        if (!exchangeToken) {
            this.notify.error('Could not acquire exchange token');
            this.router.navigate(['login']);

            return;
        }

        this.authService
            .authenticateWithExchangeToken(exchangeToken, type)
            .then(() => {
                if (type === 'IMPERSONATE') {
                    localStorage.setItem('imp', '1');
                }
                localStorage.setItem('authMethod', 'google');
                this.router.navigate(['/dashboard']);
            })
            .catch((err) => this.onLoginFailure(err));
    }

    /**
     * User could not be authenticated, notify error
     */
    private onLoginFailure(err: JsonApiError): void {
        if (!err || !err.status) {
            this.notify.error('Login failed.');
        } else {
            switch (err.status) {
                case 423:
                    this.notify.error('Account Locked - Password Reset Required');
                    this.router.navigate(['reset']);
                    break;
                case 412:
                    this.notify.error('Account does not exist for this email');
                    this.router.navigate(['login']);
                    break;
                default:
                    this.notify.error('Email or password incorrect');
                    break;
            }
        }

        this.isLoading = false;
    }
}
